*,
*:before,
*:after {
  box-sizing: border-box;
}

.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
  z-index: 100;
}

body {
  font: 16px / 1 var(--base-font-family-1);
  color: var(--base-color-dark);
}

a {
  text-decoration: none;
  outline: none;
}

button {
  outline: none;
}
