@font-face {
  font-family: 'icons';
  src:  url('/static/icons/icons.eot?o7uql6');
  src:  url('/static/icons/icons.eot?o7uql6#iefix') format('embedded-opentype'),
    url('/static/icons/icons.ttf?o7uql6') format('truetype'),
    url('/static/icons/icons.woff?o7uql6') format('woff'),
    url('/static/icons/icons.svg?o7uql6#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag-filled:before {
  content: "\e915";
}
.icon-christmas-tree:before {
  content: "\e94d";
}
.icon-bag:before {
  content: "\e94a";
}
.icon-truck:before {
  content: "\e94b";
}
.icon-chat-help:before {
  content: "\e94c";
}
.icon-comment:before {
  content: "\e924";
}
.icon-tiktok:before {
  content: "\e948";
}
.icon-linkedin:before {
  content: "\e949";
}
.icon-discount:before {
  content: "\e944";
}
.icon-geo-arrow:before {
  content: "\e943";
}
.icon-plus:before {
  content: "\e941";
}
.icon-minus:before {
  content: "\e942";
}
.icon-fee:before {
  content: "\e940";
}
.icon-cart-outlined:before {
  content: "\e93f";
}
.icon-bell:before {
  content: "\e93d";
}
.icon-bell-full:before {
  content: "\e93e";
}
.icon-add-cart:before {
  content: "\e93c";
}
.icon-comment-filled:before {
  content: "\e93b";
}
.icon-alcohol:before {
  content: "\e939";
}
.icon-pack:before {
  content: "\e93a";
}
.icon-arrow-back:before {
  content: "\e938";
}
.icon-arrow-up:before {
  content: "\e937";
}
.icon-fresh:before {
  content: "\e935";
}
.icon-shopper:before {
  content: "\e936";
}
.icon-price:before {
  content: "\e934";
}
.icon-phone-header:before {
  content: "\e91c";
}
.icon-ellipse:before {
  content: "\e933";
}
.icon-card:before {
  content: "\e932";
}
.icon-basket:before {
  content: "\e800";
}
.icon-caret-bottom:before {
  content: "\e901";
}
.icon-caret-top:before {
  content: "\e902";
}
.icon-clock:before {
  content: "\e903";
}
.icon-loupe:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e905";
}
.icon-pin:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e907";
}
.icon-letter:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-heart-full:before {
  content: "\e90b";
}
.icon-heart:before {
  content: "\e90c";
}
.icon-arrow-small-left:before {
  content: "\e90d";
}
.icon-arrow-small-right:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-list:before {
  content: "\e911";
}
.icon-close:before {
  content: "\e912";
}
.icon-delivery:before {
  content: "\e913";
}
.icon-weight:before {
  content: "\e914";
}
.icon-delete:before {
  content: "\e916";
}
.icon-like:before {
  content: "\e917";
}
.icon-contact:before {
  content: "\e918";
}
.icon-update:before {
  content: "\e919";
}
.icon-uah:before {
  content: "\e91a";
}
.icon-24-hours:before {
  content: "\e91b";
}
.icon-ukr-poshta:before {
  content: "\e91d";
}
.icon-nova-poshta:before {
  content: "\e91e";
}
.icon-eye-close:before {
  content: "\e91f";
}
.icon-eye:before {
  content: "\e920";
}
.icon-recipes:before {
  content: "\e921";
}
.icon-categories:before {
  content: "\e922";
}
.icon-big-recipes:before {
  content: "\e923";
}
.icon-question:before {
  content: "\e925";
}
.icon-filters:before {
  content: "\e926";
}
.icon-pencil:before {
  content: "\e927";
}
.icon-print:before {
  content: "\e928";
}
.icon-download:before {
  content: "\e929";
}
.icon-add-friend:before {
  content: "\e92a";
}
.icon-settings:before {
  content: "\e92b";
}
.icon-calendar:before {
  content: "\e92c";
}
.icon-exit:before {
  content: "\e92d";
}
.icon-star:before {
  content: "\e92e";
}
.icon-facebook:before {
  content: "\e92f";
}
.icon-twitter:before {
  content: "\e930";
}
.icon-briefcase:before {
  content: "\e931";
}
.icon-referral:before {
  content: "\e900";
}
.icon-arrow-up-right:before {
  content: "\e945";
}
.icon-telegram:before {
  content: "\e946";
}
.icon-instagram:before {
  content: "\e947";
}
